import service from "./index";
import functionsCommon from "@/common/functions";

const resourcePath = "/contact-us";
export default {
    getAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit;
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
};
