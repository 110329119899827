<template>
  <div>
    <topic-component :topic="$t('SideBarItems.contactUs.text')"></topic-component>
    <div class="contact-us-container">
      <div v-for="(item, index) in items" :key="index + '_new_card'">
        <div class="card-contact">
          <div class="image-container">
            <div class="position-absolute-middle">
              <div class="first-cycle">
                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                  <circle opacity="0.15" cx="80" cy="80" r="78" :stroke="primary_color" stroke-width="5"
                          stroke-linejoin="round"
                          stroke-dasharray="60 60"/>
                </svg>
              </div>
            </div>
            <div class="position-absolute-middle">
              <div class="second-cycle">
                <svg xmlns="http://www.w3.org/2000/svg" width="123" height="123" viewBox="0 0 123 123" fill="none">
                  <circle opacity="0.2" cx="61.5" cy="61.5" r="59" :stroke="primary_color" stroke-width="5"
                          stroke-linejoin="round" stroke-dasharray="15 15"/>
                </svg>
              </div>
            </div>
            <div class="position-absolute-middle">
              <div class="image-middle-block">
                <img :src="`${img_icon(item.channel)}`" class="img-fluid" width="150"/>
              </div>
            </div>
          </div>
          <div style="max-width: 220px;" class="m-auto">
            <b-button
                v-if="item.channel === 'EMAIL' || item.channel === 'PHONE'"
                :variant="`${colorVariant(item.channel)}`"
                @click="copy(item.title); showTooltip[item.title] = true;"
                block
                pill
                class="mt-3 d-flex align-items-center justify-content-center"
                :id="'tooltip-' + item.title"
            >
              <b-tooltip :target="'tooltip-' + item.title" :show.sync="showTooltip[item.title]"
                         @shown="hideTooltipLater(item.title)" triggers title="Copied"></b-tooltip>
              <i :class="`${icon(item.channel)}`" class="mr-2"></i>
              <div class="ellipsis">
                {{ item.title }}
              </div>
            </b-button>
            <b-button
                v-else
                @click="openURL(item.url)"
                :variant="`${colorVariant(item.channel)}`"
                block
                pill
                class="mt-3 d-flex align-items-center justify-content-center"
            >
              <i :class="`${icon(item.channel)}`" class="mr-2"></i>
              <div class="ellipsis">
                {{ item.title }}
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-row class="ml-2 mr-2 mt-5" align-h="center">
      <b-col sm="12" md="4" lg="4" v-for="(item, index) in items" :key="index">
        <b-card class="mb-4 text-center border" border-variant="success">
          <img :src="`${img_icon(item.channel)}`" class="img-fluid rounded-circle" width="150" />
          <h3 class="card-title">{{ item.channel }}</h3>
          <b-button v-if="item.channel === 'EMAIL' || item.channel === 'PHONE'"
            @click="copy(item.title); showTooltip[item.title] = true;" block pill
            class="mt-3 d-flex align-items-center justify-content-center" :id="'tooltip-' + item.title">
            <b-tooltip :target="'tooltip-' + item.title" :show.sync="showTooltip[item.title]"
              @shown="hideTooltipLater(item.title)" triggers title="Copied"></b-tooltip>
            <i :class="`${icon(item.channel)}`" class="mr-2"></i>
            {{ item.title }}
          </b-button>
          <b-button v-else @click="openURL(item.url)" block pill :variant="`${colorVariant(item.channel)}`"
            class="mt-3 d-flex align-items-center justify-content-center">
            <i :class="`${icon(item.channel)}`" class="mr-2"></i>
            {{ item.title }}
          </b-button>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent";
import apiContactUs from "@/repository/apiContactUs";
import alert from "@/common/alert";
import {mapState} from 'vuex'

export default {
  name: "index",
  components: {
    TopicComponent,
    apiContactUs,
  },
  computed: {
    ...mapState({
      primary_color: state => state.theme.primaryColor,
      secondary_color: state => state.theme.secondaryColor,
      primary_color_array: state => state.theme.primaryColorRGB,
      secondary_color_array: state => state.theme.secondaryColorRGB,
    }),
  },
  data: () => ({
    items: [],
    showTooltip: {},
  }),
  mounted() {
    this.findContactUs();
  },
  methods: {
    copy(title) {
      navigator.clipboard.writeText(title);
    },
    hideTooltipLater(channel) {
      setTimeout(() => {
        this.showTooltip[channel] = false;
      }, 2000);
    },
    showTool() {
      this.tool = true
      setTimeout(() => this.tool = false, 3000)
    },
    openURL(url) {
      window.open(url), "_blank";
    },
    colorBorderVariant(data) {
      switch (data) {
        case "FACEBOOK":
          return "primary";
          break;
        case "LINE":
          return "success";
          break;
        case "INSTAGRAM":
          return "danger";
          break;
        case "EMAIL":
          return "info";
          break;
        case "PHONE":
          return "secondary";
          break;
        default:
          return;
      }
    },
    colorVariant(data) {
      switch (data) {
        case "FACEBOOK":
          return "outline";
          break;
        case "LINE":
          return "outline";
          break;
        case "INSTAGRAM":
          return "outline";
          break;
        case "EMAIL":
          return "outline";
          break;
        case "PHONE":
          return "outline";
          break;
        default:
          return;
      }
    },
    importSVGFile(image_name) {
      const image = require(`@/assets/images/social/${image_name}.svg`)
      return image
    },
    img_icon(data) {
      switch (data) {
        case "FACEBOOK":
          return this.importSVGFile("facebook");
          break;
        case "LINE":
          return this.importSVGFile("line");
          break;
        case "INSTAGRAM":
          return this.importSVGFile("instagram");
          break;
        case "EMAIL":
          return this.importSVGFile("email");
          break;
        case "PHONE":
          return this.importSVGFile("phone-call");
          break;
        default:
          return;
      }
    },
    icon(data) {
      switch (data) {
        case "FACEBOOK":
          return "fab fa-facebook-f";
          break;
        case "LINE":
          return "fab fa-line";
          break;
        case "INSTAGRAM":
          return "fab fa-instagram";
          break;
        case "EMAIL":
          return "fas fa-copy";
          break;
        case "PHONE":
          return "fas fa-copy";
          break;
        default:
          return;
      }
    },
    findContactUs() {
      apiContactUs
          .getAll()
          .then((response) => {
            if (response?.codeSYS === "001") {
              this.totalRows = response.total;
              this.items = response.data;
              // create variable for tooltip
              this.showTooltip = this.items.reduce((prev, next) => ({...prev, [next.title]: false}), {})
            } else {
              alert.deleteFailed(this, response.message);
            }
          })
          .catch((err) => {
            alert.findFailed(this, err);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotating {
  from {
    transform: rotate(70deg);
  }

  to {
    transform: rotate(430deg);
  }
}

.contact-us-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fff;
  padding: 48px;
  border-radius: 14px;
}

.card-contact {
  width: 250px;
  height: 300px;
  position: relative;

  .image-container {
    width: 200px;
    height: 200px;
    position: relative;
    margin: auto;

    .first-cycle {
      transition: 0.3s;
      animation: rotating 6s linear infinite paused;
    }

    .second-cycle {
      transition: 0.3s;
      animation: rotating 3s linear infinite paused;
    }

    .image-middle-block {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(var(--primary-color-rgb), 0.2);

      img {
        height: 48px;
        width: 48px;
        border-radius: 12px;
      }
    }

  }

  &:focus,
  &:active,
  &:hover {
    .first-cycle {
      animation: rotating 6s linear infinite;
    }

    .second-cycle {
      animation: rotating 3s linear infinite;
    }
  }
}

.position-absolute-middle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}</style>